import { useState, useEffect } from 'react';

const useFlattenedGameData = (initialData: Array<any | any[]>) => {
    const [flattenedData, setFlattenedData] = useState<any[]>([]);

    // Veriyi düzleştiren fonksiyon
    const flattenData = (data: Array<any | any[]>) => {
        const flattenedArray: any[] = data.reduce((acc: any[], curr: any | any[]) => {
            if (Array.isArray(curr)) {
                acc.push(...curr);
            } else {
                acc.push(curr);
            }
            return acc;
        }, []);

        return flattenedArray;
    };

    // Başlangıçta veriyi düzleştir
    useEffect(() => {
        if (initialData && initialData.length) {
            setFlattenedData(flattenData(initialData));
        }
    }, [initialData]);

    return flattenedData;
};

export default useFlattenedGameData;
